import React, { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby-link";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import packService from "~/utils/api/v1/packService";
import { countriesNoTilde } from "~/utils/data/constants";
import Pack from "~/utils/interfaces/Pack";
import PackCategory from "~/utils/interfaces/PackCategory";

interface Props {
  id: string;
}

const EditPack = ({ id }: Props): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<PackCategory[]>([]);
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);
  const [packData, setPackData] = useState<Pack>({
    id: "",
    active: false,
    fonasa: false,
    promotion: false,
    country: "Chile",
    category: "",
    cover_image_link: "",
    description: "",
    final_price: 0,
    strikethrough_price: 0,
    name: "",
    number_of_patients: 0,
    results_time: 24,
    services: [],
    sources: [],
    stackable: false,
    tag: "",
    web_display_order: 0,
    external_reference: "",
  });

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await packService.updatePack(id, packData);
      Swal.fire({
        icon: req.status === 200 ? "success" : "error",
        title: req.status === 200 ? "Pack actualizado con éxito" : "Error al actualizar pack",
        text:
          req.status === 200
            ? `Información del pack ${packData.name} actualizada con éxito`
            : "Error al actualizar el pack",
        showConfirmButton: true,
        didClose: () => {
          navigate(`/packs/${id}/`);
        },
      });
    } catch (err) {
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setPackData((prevState: Pack): Pack => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const newErrors: string[] = [];

    // check for errors in the form
    if (!packData.name.length) {
      newErrors.push("El nombre es obligatorio");
    }
    if (packData.final_price <= 0) {
      newErrors.push("El precio final es incorrecto");
    }
    if (packData.strikethrough_price && packData.strikethrough_price < 0) {
      newErrors.push("El precio de referencia es incorrecto");
    }
    if (packData.results_time && packData.results_time < 0) {
      newErrors.push("El tiempo de resultados es incorrecto");
    }
    if (packData.number_of_patients && packData.number_of_patients < 0) {
      newErrors.push("El número de pacientes es incorrecto");
    }
    if (!packData.description) {
      newErrors.push("La descripción es obligatoria");
    }
    if (!packData.tag) {
      newErrors.push("El tag es obligatorio");
    }
    if (!packData.category) {
      newErrors.push("La categoría es obligatoria");
    }
    if (!packData.sources) {
      newErrors.push("Las sales sources son obligatorias");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  useEffect(() => {
    const fetchCategories = async (): Promise<void> => {
      try {
        const res = await packService.fetchCategories();
        setCategories(res.data);
      } catch (err) {
        setErrors([err.message]);
      }
    };

    const fetchPackData = async () => {
      setLoading(true);
      try {
        const req = await packService.fetchPack(id);
        const data: Pack = req.data;
        setPackData(data);
      } catch (err) {
        setErrors([err.message]);
      }
      setLoading(false);
    };

    const fetchSalesSources = async () => {
      try {
        const res = await appointmentService.fetchSalesSources();
        const salesSourceList = res.data.data;
        const clientsRes = await alliancesService.fetchClients();
        const clients = clientsRes.data;
        const clientsSalesSource = clients.map((client) => client.sales_source);
        const mergedSaleSources = new Set<string>(salesSourceList.concat(clientsSalesSource));
        const sortedMergedSaleSources = Array.from(mergedSaleSources).sort((a, b) => {
          return a.localeCompare(b);
        });
        setSalesSourcesList(sortedMergedSaleSources);
      } catch (err) {
        setErrors([err.message]);
      }
    };

    fetchCategories();
    fetchSalesSources();
    fetchPackData();
  }, []);

  return (
    <PrivateRoute>
      <div className="flex justify-center">
        <div className="p-12 rounded-lg bg-white border border-gray-200 flex flex-col">
          <h2 className="mb-8">Actualizar pack</h2>

          {loading && (
            <div className="flex flex-row flex-nowrap items-center justify-center">
              <h4>Cargando información</h4>
            </div>
          )}

          <div className="text-red-500 flex justify-center">
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="name"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.name}
                helperText="Nombre del pack"
                fullWidth
              />
              <TextField
                id="description"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.description}
                helperText="Descripción"
                fullWidth
              />
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="final_price"
                type="number"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.final_price}
                helperText="Precio final"
                fullWidth
              />
              <TextField
                id="strikethrough_price"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.strikethrough_price}
                helperText="Precio de referencia, tachado"
                fullWidth
              />
            </div>

            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="cover_image_link"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.cover_image_link}
                helperText="Link de la imagen de portada"
                fullWidth
              />
              <TextField
                id="results_time"
                type="number"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.results_time}
                helperText="Tiempo de resultados"
                fullWidth
              />
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="number_of_patients"
                type="number"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.number_of_patients}
                helperText="Número de pacientes"
                fullWidth
              />
              <TextField
                id="web_display_order"
                type="number"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.web_display_order}
                helperText="Posición en la web"
                fullWidth
              />
            </div>

            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="tag"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.tag}
                helperText="Tag"
                fullWidth
              />
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <FormControl fullWidth>
                <InputLabel id="stackable">Stackable</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.stackable}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      stackable: e.target.value as boolean,
                    });
                  }}
                  fullWidth
                >
                  <MenuItem value="true">Si</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="fonasa">Fonasa</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.fonasa}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      fonasa: e.target.value as boolean,
                    });
                  }}
                  fullWidth
                >
                  <MenuItem value="true">Si</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <FormControl fullWidth>
                <InputLabel id="country">País</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.country}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      country: e.target.value as string,
                    });
                  }}
                  fullWidth
                >
                  {countriesNoTilde.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="promotion">Promoción</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.promotion}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      promotion: e.target.value as boolean,
                    });
                  }}
                  fullWidth
                >
                  <MenuItem value="true">Si</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <FormControl fullWidth>
                <InputLabel id="category">Categoría</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.category}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      category: e.target.value as string,
                    });
                  }}
                  fullWidth
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="active">Activo</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.active}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      active: e.target.value as boolean,
                    });
                  }}
                  fullWidth
                >
                  <MenuItem value="true">Si</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <FormControl fullWidth>
                <InputLabel id="sources">Sources</InputLabel>
                <Select
                  disabled={loading}
                  value={packData.sources || []}
                  onChange={(e) => {
                    setPackData({
                      ...packData,
                      sources: e.target.value as string[],
                    });
                  }}
                  multiple
                  fullWidth
                >
                  {salesSourcesList.map((salesSource) => (
                    <MenuItem key={salesSource} value={salesSource}>
                      {salesSource}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-row items-center justify-center space-x-4">
              <TextField
                id="external_reference"
                disabled={loading}
                placeholder={loading ? "Cargando..." : ""}
                onChange={formChange}
                value={packData.external_reference}
                helperText="External reference"
                fullWidth
              />
            </div>
          </div>
          <div className="flex items-center justify-center my-8 gap-x-4">
            <button
              className="text-red-500 bg-white text-sm hover:opacity-70 uppercase border-[1.5px] border-red-500 rounded-md px-12 py-2"
              disabled={loading}
              onClick={() => {
                navigate(`/packs/${id}/`);
              }}
            >
              Cancelar
            </button>
            <button
              className="text-[#039BE5] bg-white text-sm hover:opacity-70 uppercase border-[1.5px] border-[#039BE5] rounded-md px-12 py-2"
              disabled={loading}
              onClick={onSubmit}
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default EditPack;
